$background:#f1eef7;
$color:#605e7e;

/*Color variables*/
$primary1:#e6465c;
$primary2:#e05c37;
$primary3:#e67605;
$primary4:#f44336;
$primary5:#fc7242;
$primary6:#ec296b;
$primary9:#f37d07;

$secondary1:#431c84;
$secondary2:#2a5ec1;
$secondary4:#263d61;
$secondary5:#2f0c6c;
$secondary6:#4840e8;
$secondary7:#7b21b2;
$secondary8:#1e11e0;
$secondary9:#462bdc;
$secondary10:#4801FF;

$info:#0ab2e6;
$success:#00e682;
$danger:#ff382b;
$warning:#ff8819;
$blue:#467fcf;
$yellow:#ffa22b;
$teal:#05e6e6;
$purple:#604dd8;
$gray:#868e96;
$indigo:#6574cd;
$white:#fff;
$black:#000;

/*white variables*/
$white-0:rgb(255, 255, 255);
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-0:rgb(0, 0, 0);
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);
